// -------------------------------------
// Offsets for 16-Column Grid        
// -------------------------------------
// 
// Help control where a column begins horizontally within the grid at the sip-grid(xl) range.




@include sip-grid(xl) {

	.s_xl\:ColPush-1 {
  	margin-left: 6.25%;
  }
  .s_xl\:ColPush-2 {
    margin-left: 12.5%;
  }
  .s_xl\:ColPush-3 {
    margin-left: 18.75%;
  }
  .s_xl\:ColPush-4 {
    margin-left: 25%;
  }
  .s_xl\:ColPush-5 {
    margin-left: 31.25%;
  }
  .s_xl\:ColPush-6 {
    margin-left: 37.5%;
  }
  .s_xl\:ColPush-7 {
    margin-left: 43.75%;
  }
  .s_xl\:ColPush-8 {
    margin-left: 50%;
  }
  .s_xl\:ColPush-9 {
    margin-left: 56.25%;
  }
  .s_xl\:ColPush-10 {
    margin-left: 62.5%;
  }
  .s_xl\:ColPush-11 {
    margin-left: 68.75%;
  }
  .s_xl\:ColPush-12 {
    margin-left: 75%;
  }
  .s_xl\:ColPush-13 {
    margin-left: 81.25%;
  }
  .s_xl\:ColPush-14 {
    margin-left: 87.5%;
  }
  .s_xl\:ColPush-15 {
    margin-left: 93.75%;
  }

}
