// -------------------------------------
// 8-Column Grid     
// -------------------------------------
// 
// Columns for the 8-Column grid. This grid is in effect at the 
// sip-grid(sm) grid range.




@include sip-grid(sm) {

  .s_sm\:Col-1 {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .s_sm\:Col-2 {
    max-width: 25%;
		flex-basis: 25%;
  }
  .s_sm\:Col-3 {
    max-width: 37.5%;
		flex-basis: 37.5%;
  }
  .s_sm\:Col-4 {
    max-width: 50%;
		flex-basis: 50%;
  }
  .s_sm\:Col-5 {
    max-width: 62.5%;
		flex-basis: 62.5%;
  }
  .s_sm\:Col-6 {
    max-width: 75%;
		flex-basis: 75%;
  }
  .s_sm\:Col-7 {
    max-width: 87.5%;
		flex-basis: 87.5%;
  }
  .s_sm\:Col-8 {
    max-width: 100%;
		flex-basis: 100%;
  }

}
