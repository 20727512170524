// -------------------------------------
// Column Ordering      
// -------------------------------------
// 
// Horizontal Ordering of columns within a specific row. The following `s_RowReverse` classes should be placed
// on a `s_Grid_Row` element.




.s_Grid_Row {

	&.s_RowReverse {
		flex-direction: row-reverse;
	}

	@include sip-grid(xl) {
		&.s_xl\:RowReverse {
			flex-direction: row-reverse;
		}
	}

	@include sip-grid(md) {
		&.s_md\:RowReverse {
			flex-direction: row-reverse;
		}
	}

	@include sip-grid(sm) {
		&.s_sm\:RowReverse {
			flex-direction: row-reverse;
		}
	}

	@include sip-grid(xs) {
		&.s_xs\:RowReverse {
			flex-direction: row-reverse;
		}
	}

}
