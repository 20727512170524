// -------------------------------------
// Row and Column Alignment       
// -------------------------------------
// 
// Control the alignment of both `.s_Grid_Row` and individual `s_Grid_Col` containers.




// Align all columns/items within a particular Grid_Row. The following `s_RowAlign` classes should be placed
// on a `s_Grid_Row` element.

.s_Grid_Row {

	&.s_RowAlign-Top {
		align-items: flex-start;
	}
	&.s_RowAlign-Center {
		align-items: center;
	}
	&.s_RowAlign-Bottom {
		align-items: flex-end;
	}

	@include sip-grid(xl) {
		&.s_xl\:RowAlign-Top {
			align-items: flex-start;
		}
		&.s_xl\:RowAlign-Center {
			align-items: center;
		}
		&.s_xl\:RowAlign-Bottom {
			align-items: flex-end;
		}
	}

	@include sip-grid(md) {
		&.s_md\:RowAlign-Top {
			align-items: flex-start;
		}
		&.s_md\:RowAlign-Center {
			align-items: center;
		}
		&.s_md\:RowAlign-Bottom {
			align-items: flex-end;
		}
	}

	@include sip-grid(sm) {
		&.s_sm\:RowAlign-Top {
			align-items: flex-start;
		}
		&.s_sm\:RowAlign-Center {
			align-items: center;
		}
		&.s_sm\:RowAlign-Bottom {
			align-items: flex-end;
		}
	}

	@include sip-grid(xs) {
		&.s_xs\:RowAlign-Top {
			align-items: flex-start;
		}
		&.s_xs\:RowAlign-Center {
			align-items: center;
		}
		&.s_xs\:RowAlign-Bottom {
			align-items: flex-end;
		}
	}

}




// Align individual columns within a particular Grid_Row. The following `s_ColAlign` classes should be placed
// on a `s_Grid_Col` element.

.s_Grid_Col {

	&.s_ColAlign-Top {
		align-self: flex-start;
	}
	&.s_ColAlign-Center {
		align-self: center;
	}
	&.s_ColAlign-Bottom {
		align-self: flex-end;
	}

	@include sip-grid(xl) {
		&.s_xl\:ColAlign-Top {
			align-self: flex-start;
		}
		&.s_xl\:ColAlign-Center {
			align-self: center;
		}
		&.s_xl\:ColAlign-Bottom {
			align-self: flex-end;
		}
	}

	@include sip-grid(md) {
		&.s_md\:ColAlign-Top {
			align-self: flex-start;
		}
		&.s_md\:ColAlign-Center {
			align-self: center;
		}
		&.s_md\:ColAlign-Bottom {
			align-self: flex-end;
		}
	}

	@include sip-grid(sm) {
		&.s_sm\:ColAlign-Top {
			align-self: flex-start;
		}
		&.s_sm\:ColAlign-Center {
			align-self: center;
		}
		&.s_sm\:ColAlign-Bottom {
			align-self: flex-end;
		}
	}

	@include sip-grid(xs) {
		&.s_xs\:ColAlign-Top {
			align-self: flex-start;
		}
		&.s_xs\:ColAlign-Center {
			align-self: center;
		}
		&.s_xs\:ColAlign-Bottom {
			align-self: flex-end;
		}
	}
			
}

