// -------------------------------------
// Grid Config        
// -------------------------------------
// 
// This file contains the required variables, maps, and mixins for generating Sipster's grid system




// Variables
//
// 1rem = 16px (this is set in ../core/base.scss)

$sip_grid_width: 100% !default;
// $sip_grid_max-width: 150rem !default;		    // 2400px 

$sip_grid_gutter: 0.75rem !default;					    // 12px
$sip_grid_gutter-sm: 0.5rem !default;			   		// 8px

// Range Endpoints
$sip_grid_range_xs-max: 37.4375rem !default;	  // 599px 

$sip_grid_range_sm-min: 37.5rem !default;				// 600px 
$sip_grid_range_sm-max: 59.9375rem !default;	  // 959px

$sip_grid_range_md-min: 60rem !default;					// 960px 
$sip_grid_range_md-max: 79.9375rem !default;	  // 1279px 

$sip_grid_range_lg-min: 80rem !default;					// 1280px 
$sip_grid_range_lg-max: 119.9375rem !default;		// 1919px

$sip_grid_range_xl-min: 120rem !default;			  // 1920px




// Grid-range Mixin       
// 
// Creates media-queries that are mapped to the responsive grid-ranges and columns

@mixin sip-grid($range) {

	@if $range == xs {
		@media screen and (max-width: $sip_grid_range_xs-max) { 
			@content;
		}
	}
	@else if $range == sm {
		@media screen and (min-width: $sip_grid_range_sm-min) and (max-width: $sip_grid_range_sm-max) { 
			@content; 
		}
	}
	@else if $range == sm-down {
    @media screen and (max-width: $sip_grid_range_sm-max) { 
      @content; 
    }
  }
	@else if $range == md {
		@media screen and (min-width: $sip_grid_range_md-min) and (max-width: $sip_grid_range_md-max) { 
			@content; 
		}
  }
  @else if $range == md-down {
    @media screen and (max-width: $sip_grid_range_md-max) { 
      @content; 
    }
  }
  @else if $range == lg {
		@media screen and (min-width: $sip_grid_range_lg-min) and (max-width: $sip_grid_range_lg-max) { 
			@content; 
		}
  }
  @else if $range == xl {
		@media screen and (min-width: $sip_grid_range_xl-min) { 
			@content; 
		}
  }
  
  @else {
    @error '"#{$range}" is not a valid grid range. Available grid ranges are: xs, sm, sm-down, md, md-down, lg, xl'; 
  }
}




// 3. Helper Mixins       
//
// Mixins to help easily create grid elements

// Gutter compensation allows the `.s_Grid_Row` element to align properly inside its parent `.s_Grid` 
// container while allowing its children columns to contain gutters (padding) and still align to the grid.
@mixin sip-gutter-compensation {
	margin-right: $sip_grid_gutter * -1;
	margin-left: $sip_grid_gutter * -1;
  
  @include sip-grid(md) {
		margin-right: $sip_grid_gutter-sm * -1;
		margin-left: $sip_grid_gutter-sm * -1;
	}

	@include sip-grid(sm) {
		margin-right: $sip_grid_gutter-sm * -1;
		margin-left: $sip_grid_gutter-sm * -1;
	}
  @include sip-grid(xs) {
		margin-right: $sip_grid_gutter-sm * -1;
		margin-left: $sip_grid_gutter-sm * -1;
	}
}
 
// Make an element a flex container
@mixin sip-flex-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

// Make an element a column 
@mixin sip-flex-column {
	display: block;
	padding-left: $sip_grid_gutter;
	padding-right: $sip_grid_gutter;
	position: relative;
	min-height: 1px;
	flex-grow: 1;
	flex-shrink: 1;
  
  @include sip-grid(md) {
		padding-left: $sip_grid_gutter-sm;
		padding-right: $sip_grid_gutter-sm;
	}

	@include sip-grid(sm) {
		padding-left: $sip_grid_gutter-sm;
		padding-right: $sip_grid_gutter-sm;
	}
  @include sip-grid(xs) {
		padding-left: $sip_grid_gutter-sm;
		padding-right: $sip_grid_gutter-sm;
	}
}

