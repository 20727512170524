// -------------------------------------
// 16-Column Grid     
// -------------------------------------
// 
// Columns for the 16-Column grid. This grid is in effect at the 
// sip-grid(xl) grid range.




@include sip-grid(xl) {

	.s_xl\:Col-1 {
  	max-width: 6.25%;
    flex-basis: 6.25%;
  }
  .s_xl\:Col-2 {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .s_xl\:Col-3 {
    max-width: 18.75%;
    flex-basis: 18.75%;
  }
  .s_xl\:Col-4 {
    max-width: 25%;
    flex-basis: 25%;
  }
  .s_xl\:Col-5 {
    max-width: 31.25%;
    flex-basis: 31.25%;
  }
  .s_xl\:Col-6 {
    max-width: 37.5%;
    flex-basis: 37.5%;
  }
  .s_xl\:Col-7 {
    max-width: 43.75%;
    flex-basis: 43.75%;
  }
  .s_xl\:Col-8 {
    max-width: 50%;
    flex-basis: 50%;
  }
  .s_xl\:Col-9 {
    max-width: 56.25%;
    flex-basis: 56.25%;
  }
  .s_xl\:Col-10 {
    max-width: 62.5%;
    flex-basis: 62.5%;
  }
  .s_xl\:Col-11 {
    max-width: 68.75%;
    flex-basis: 68.75%;
  }
  .s_xl\:Col-12 {
    max-width: 75%;
    flex-basis: 75%;
  }
  .s_xl\:Col-13 {
    max-width: 81.25%;
    flex-basis: 81.25%;
  }
  .s_xl\:Col-14 {
    max-width: 87.5%;
    flex-basis: 87.5%;
  }
  .s_xl\:Col-15 {
    max-width: 93.75%;
    flex-basis: 93.75%;
  }
  .s_xl\:Col-16 {
    max-width: 100%;
    flex-basis: 100%;
  }

}
