// -------------------------------------
// Base                   
// -------------------------------------
// 
// Defines semantic variables for base theming as well as foundational styling for bare HTML elements. 
// With the exception of `_reset.scss`, this is the only section of `/core` that should output any actual CSS.

// CSS custom properties are used for styles that often vary between themes or are commonly customized.

:root {
  --sip_ui_background-color: #{sip-color(grey, 100)};
  --sip_ui_color: #{sip-color(grey, 700)};

  --sip_ui_focus_border: 1px solid;
  --sip_ui_focus_border-color: #{sip-color(primary)};
  --sip_ui_focus_shadow: #{sip-shadow(1)}, 0px 2px 8px rgba( 34,151,255, 0.48 );
  
  --sip_ui_border-radius: 2px;

  --sip_hr_background-color: #{sip-color(grey, 300)};
  --sip_hr_height: 1px;
  --sip_hr_margin: #{sip-spc(24) 0};
}




html {
  font-size: $sip_ui_font-size;
  line-height: $sip_ui_lineheight;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: $sip_ui_text-rendering;
  text-size-adjust: 100%;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $sip_ui_font-family;
}

body {
  @include sip-typeset(body);
  color: var(--sip_ui_color);
  background-color: var(--sip_ui_background-color);
}

a {
  cursor: pointer;
  outline: none;
  text-decoration: none;

  strong {
    color: currentColor;
  }
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $sip_code_font-family;
}

code {
  font-size: $sip_code_font-size;
  font-weight: $sip_code_font-weight;
}

pre {
  -webkit-overflow-scrolling: touch;

  font-size: $sip_code_font-size;
  overflow-x: auto;
  white-space: pre;
  word-wrap: normal;

  code {
    background-color: transparent;
    color: inherit;
    font-size: 1em;
    padding: sip-spc(0);
  }
}

img {
  height: auto;
  max-width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: map-get($sip_type_sizes, 12);
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: $sip_strong_font-weight;
}

hr {
  background-color: var(--sip_hr_background-color);
  border: 0;
  display: block;
  height: var(--sip_hr_height);
  margin: var(--sip_hr_margin);
}

fieldset {
  border: none;
}

table {
  td,
  th {
    text-align: left;
    vertical-align: top;
  }
}
