// -------------------------------------
// 12-Column Grid  @sip-grid(md)     
// -------------------------------------
// 
// Columns for the 12-Column grid. The default columns `s_Col-{num}` also cover this grid range. 
// These specific column classes serve as supplementary class hooks in case a specific layout needs
// to be achived at only the sip-grid(md) range. 




@include sip-grid(md) {

	.s_md\:Col-1 {
	  max-width: 8.33333333333333%;
		flex-basis: 8.33333333333333%;
	}
	.s_md\:Col-2 {
	  max-width: 16.66666666666666%;
		flex-basis: 16.66666666666666%;
	}
	.s_md\:Col-3 {
	  max-width: 25%;
		flex-basis: 25%;
	}
	.s_md\:Col-4 {
	  max-width: 33.33333333333333%;
		flex-basis: 33.33333333333333%;
	}
	.s_md\:Col-5 {
	  max-width: 41.66666666666666%;
		flex-basis: 41.66666666666666%;
	}
	.s_md\:Col-6 {
	  max-width: 50%;
		flex-basis: 50%;
	}
	.s_md\:Col-7 {
	  max-width: 58.33333333333333%;
		flex-basis: 58.33333333333333%;
	}
	.s_md\:Col-8 {
	  max-width: 66.66666666666666%;
		flex-basis: 66.66666666666666%;
	}
	.s_md\:Col-9 {
	  max-width: 75%;
		flex-basis: 75%;
	}
	.s_md\:Col-10 {
	  max-width: 83.33333333333333%;
		flex-basis: 83.33333333333333%;
	}
	.s_md\:Col-11 {
	  max-width: 91.66666666666666%;
		flex-basis: 91.66666666666666%;
	}
	.s_md\:Col-12 {
	  max-width: 100%;
		flex-basis: 100%;
	}

}
