// -------------------------------------
// Offsets for 4-Column Grid        
// -------------------------------------
// 
// Help control where a column begins horizontally within the grid at the sip-grid(sm) range.




@include sip-grid(xs) {

  .s_xs\:ColPush-1 {
    margin-left: 25%;
  }
  .s_xs\:ColPush-2 {
    margin-left: 50%;
  }
  .s_xs\:ColPush-3 {
    margin-left: 75%;
  }

}

