// -------------------------------------
// Offsets for Default 12-Column Grid        
// -------------------------------------
// 
// Help control where a column begins horizontally within the grid.




.s_ColPush-1 {
	margin-left: 8.33333333333333%;
}

.s_ColPush-2 {
	margin-left: 16.66666666666666%;
}

.s_ColPush-3 {
	margin-left: 25%;
}

.s_ColPush-4 {
	margin-left: 33.33333333333333%;
}

.s_ColPush-5 {
	margin-left: 41.66666666666666%;
}

.s_ColPush-6 {
	margin-left: 50%;
}

.s_ColPush-7 {
	margin-left: 58.33333333333333%;
}

.s_ColPush-8 {
	margin-left: 66.66666666666666%;
}

.s_ColPush-9 {
	margin-left: 75%;
}

.s_ColPush-10 {
	margin-left: 83.33333333333333%;
}

.s_ColPush-11 {
	margin-left: 91.66666666666666%;
}
