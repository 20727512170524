// -------------------------------------
// Link                   
// -------------------------------------
// 
// This file contains the required variables, maps, and functions for link components

// Dynamic variables for links
:root {
  --sip_link_color: #{sip-color(primary)};
  --sip_link_background-color: transparent;
  --sip_link_padding-top: #{sip-spc(0)};
  --sip_link_padding-right: #{sip-spc(4)};
  --sip_link_padding-bottom: #{sip-spc(0)};
  --sip_link_padding-left: #{sip-spc(4)};
  --sip_link_decoration: none;

  --sip_link_hover_color: #{sip-color(primary,500)};
  --sip_link_hover_decoration: underline;
  
  --sip_link_focus_color: var(--sip_link_hover_color);
  --sip_link_focus_border: var(--sip_ui_focus_border);
  --sip_link_focus_border-color: var(--sip_ui_focus_border-color);
  --sip_link_focus_shadow: var(--sip_ui_focus_shadow);
  --sip_link_focus_decoration: var(--sip_link_decoration);
  
  --sip_link_active_color: var(--sip_link_hover_color);
  --sip_link_active_border: var(--sip_link_focus_border);
  --sip_link_active_border-color: var(--sip_link_focus_border-color);
  --sip_link_active_decoration: var(--sip_link_decoration);

  --sip_link_visited_color: var(--sip_link_hover_color);
}




.s_Link {
  color: var(--sip_link_color);
  cursor: pointer;
  outline: none;
  text-decoration: var(--sip_link_decoration);
  padding-top: var(--sip_link_padding-top);
  padding-right: var(--sip_link_padding-right);
  padding-bottom: var(--sip_link_padding-bottom);
  padding-left: var(--sip_link_padding-left);
  border-radius: var(--sip_ui_border-radius);

  &:hover {
    color: var(--sip_link_hover_color);
    text-decoration: var(--sip_link_hover_decoration);
  }
  
  &:focus {
    color: var(--sip_link_focus_color);
    box-shadow: var(--sip_link_focus_shadow);
    border: var(--sip_link_focus_border);
    border-color: var(--sip_link_focus_border-color);
    text-decoration: var(--sip_link_focus_decoration);
  }
}
