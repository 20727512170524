// -------------------------------------
// 4-Column Grid     
// -------------------------------------
// 
// Columns for the 4-Column grid. This grid is in effect at the 
// sip-grid(xx) grid range.




@include sip-grid(xs) {

  .s_xs\:Col-1 {
    max-width: 25%;
    flex-basis: 25%;
  }
  .s_xs\:Col-2 {
    max-width: 50%;
	flex-basis: 50%;
  }
  .s_xs\:Col-3 {
    max-width: 75%;
		flex-basis: 75%;
  }
  .s_xs\:Col-4 {
    max-width: 100%;
		flex-basis: 100%;
  }

}
