// -------------------------------------
// Global Mixins                  
// -------------------------------------
// 
// Globally available mixins for Sipster CSS.





// Hide from both screenreaders and browsers

@mixin hidden {
  display: none;
  visibility: hidden;
}




// Form input placeholder text

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}




// Easily truncate any element
// Requirements: should only be used on elements that have `display: block;` or `display: inline-block;`

@mixin truncate($threshold) {
    max-width: $threshold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}




// Overflow touch: adds touch scrolling to elements

@mixin overflow-touch {
  -webkit-overflow-scrolling: touch;
}




// returns all 'key:value,' properties from a $map in css syntax

@mixin return-prop-val($map) {
  @each $name, $value in $map {
    #{$name}: $value;
  }
}




// Generate All Your Utility Classes with Sass Maps: frontstuff.io/generate-all-your-utility-classes-with-sass-maps

@mixin mk-modifiers($map, $attribute, $prefix: '_', $separator: '--', $base: 'base') {
  @each $key, $value in $map {
    &#{if($key != $base, #{$prefix}#{$key}, '')} {
      @if type-of($value) == 'map' {
        @include mk-modifiers($value, $attribute, $separator);
      }
      @else {
        #{$attribute}: $value;
      }
    }
  }
}
// Example:

// .element {  
//     @include mk-modifiers($sip-colors, 'color');
// }
//
// will result in:
//
// .element_color--modifier
