// -------------------------------------
// Default 12-Column Grid        
// -------------------------------------
// 
// Columns for the default 12-Column grid. This grid is in effect at the 
// sip-grid(lg) and sip-grid(md) grid ranges.




.s_Col-1 {
  max-width: 8.33333333333333%;
	flex-basis: 8.33333333333333%;
}

.s_Col-2 {
  max-width: 16.66666666666666%;
	flex-basis: 16.66666666666666%;
}

.s_Col-3 {
  max-width: 25%;
	flex-basis: 25%;
}

.s_Col-4 {
  max-width: 33.33333333333333%;
	flex-basis: 33.33333333333333%;
}

.s_Col-5 {
  max-width: 41.66666666666666%;
	flex-basis: 41.66666666666666%;
}

.s_Col-6 {
  max-width: 50%;
	flex-basis: 50%;
}

.s_Col-7 {
  max-width: 58.33333333333333%;
	flex-basis: 58.33333333333333%;
}

.s_Col-8 {
  max-width: 66.66666666666666%;
	flex-basis: 66.66666666666666%;
}

.s_Col-9 {
  max-width: 75%;
	flex-basis: 75%;
}

.s_Col-10 {
  max-width: 83.33333333333333%;
	flex-basis: 83.33333333333333%;
}

.s_Col-11 {
  max-width: 91.66666666666666%;
	flex-basis: 91.66666666666666%;
}

.s_Col-12 {
  max-width: 100%;
	flex-basis: 100%;
}
