// -------------------------------------
// Offsets for 8-Column Grid        
// -------------------------------------
// 
// Help control where a column begins horizontally within the grid at the sip-grid(sm) range.




@include sip-grid(sm) {

  .s_sm\:ColPush-1 {
    margin-left: 12.5%;
  }
  .s_sm\:ColPush-2 {
    margin-left: 25%;
  }
  .s_sm\:ColPush-3 {
    margin-left: 37.5%;
  }
  .s_sm\:ColPush-4 {
    margin-left: 50%;
  }
  .s_sm\:ColPush-5 {
    margin-left: 62.5%;
  }
  .s_sm\:ColPush-6 {
    margin-left: 75%;
  }
  .s_sm\:ColPush-7 {
    margin-left: 87.5%;
  }

}
