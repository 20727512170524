// -------------------------------------
// Offsets for 12-Column Grid        
// -------------------------------------
// 
// Help control where a column begins horizontally within the grid at the sip-grid(md) range.




@include sip-grid(md) {

	.s_md\:ColPush-1 {
	  margin-left: 8.33333333333333%;
	}
	.s_md\:ColPush-2 {
	  margin-left: 16.66666666666666%;
	}
	.s_md\:ColPush-3 {
	  margin-left: 25%;
	}
	.s_md\:ColPush-4 {
	  margin-left: 33.33333333333333%;
	}
	.s_md\:ColPush-5 {
	  margin-left: 41.66666666666666%;
	}
	.s_md\:ColPush-6 {
	  margin-left: 50%;
	}
	.s_md\:ColPush-7 {
	  margin-left: 58.33333333333333%;
	}
	.s_md\:ColPush-8 {
	  margin-left: 66.66666666666666%;
	}
	.s_md\:ColPush-9 {
	  margin-left: 75%;
	}
	.s_md\:ColPush-10 {
	  margin-left: 83.33333333333333%;
	}
	.s_md\:ColPush-11 {
	  margin-left: 91.66666666666666%;
	}

}
