// -------------------------------------
// Grid Main        
// -------------------------------------
// 
// Sets core functionality and layout for grid elements.




.s_Grid {
  margin: 0 auto;
	position: relative;
  padding-right: $sip_grid_gutter;
	padding-left: $sip_grid_gutter;
	width: calc( #{$sip_grid-width} - ( #{$sip_grid-gutter} * 2) );

  @include sip-grid(md) {
    padding-right: $sip_grid_gutter-sm;
    padding-left: $sip_grid_gutter-sm;
    width: calc( #{$sip_grid-width} - ( #{$sip_grid-gutter-sm} * 2) );
  }
	@include sip-grid(sm) {
		padding-right: $sip_grid_gutter-sm;
	  padding-left: $sip_grid_gutter-sm;
		width: calc( #{$sip_grid-width} - ( #{$sip_grid-gutter-sm} * 2) );
	}
  @include sip-grid(xs) {
    padding-right: $sip_grid_gutter-sm;
	  padding-left: $sip_grid_gutter-sm;
		width: calc( #{$sip_grid-width} - ( #{$sip_grid-gutter-sm} * 2) );
  }
}

.s_Grid_Row {
  @include sip-flex-container;
  @include sip-gutter-compensation;
  flex-direction: row;
  flex-wrap: wrap;
}

.s_Grid_Col {
	@include sip-flex-column;
}
