// -------------------------------------
// Theme - Dark        
// -------------------------------------
// 
// Default variables and properties that comprise the dark theme.




:root[data-theme="s_Theme-Dark"] {
  
  --sip_ui_background-color: #{sip-color(grey, 700)};
  --sip_ui_color: #{sip-color(grey, 100)};
  
  --sip_link_color: #{sip-color(danger)};
  --sip_link_hover-color: #{sip-color(danger,500)};
  
  --sip_ui_border-radius: 2px;
  
  --sip_hr_background-color: #{sip-color(grey, 500)};
  
}
